import React from 'react'
import { FeaturePage } from '../components/FeaturePage'

export default () => (
  <FeaturePage
    heading1="Link your live availability to your online customer bookings journey"
    heading2="Cut down on the countless hours spent manually taking and organising bookings over the phone or email by driving them straight through your website."
    heading3="Flexible bookings journey at scale"
    headerBlocks={[
      "Discover new revenue potential through a seamless bookings journey linked to your staff schedule",
      "Create upsell opportunities through additional treatments and products during the checkout process",
      "Eliminate team hours spent manually taking reservations by phone and email with an online bookings process linked straight to your live availability",
    ]}
    featurePageBlocks={[
      {
        chip: 'Reservation management',
        heading: 'Select the bookings journey that works for your treatment, classes and packages',
        body: [
          "Automate your bookings flow for treatments, classes, packages, and breaks where you’re sure on practitioner head count. This will automatically feed into your staff availability within Trybe. Drive down team hours spent processing bookings over phone and email by linking your online bookings process straight to your operational software. Begin to take bookings and pre-payment during peak reservation hours at night when the team conventionally wouldn’t be in the office to receive them.",
          "For bookings later down the line where you’re not sure on employee availability, you’ve got the ability to take an enquiry with an optional card authentication to secure, manage your staff schedule, and then confirm the booking.",
        ]
      },
      {
        chip: 'Payment and card authentication',
        heading: 'Source revenue before the date of the booking',
        body: [
          'Automate payments for real-time reservations at point of booking. Leverage the ability to carry out card authentications at point of booking for treatments, packages, and breaks that are set as an enquiry.',
        ],
      },
      {
        chip: 'Upsell at the point of booking',
        heading: 'Secure additional revenue during the customer checkout process',
        body: [
          'Display additional treatments and in-house products during the customer check-out process and secure the payment at point of booking.',
        ],
      },
      {
        chip: 'Staff rota management',
        heading: 'Easily monitor and manage your team rota within Trybe',
        body: [
          'Manage and set your team rotas with simple and easy-to-use tools within Trybe. This can be set as weekly rolling rotas and can then be controlled on a daily basis such as setting annual leave. This availability is directly linked to your live online availability that can be booked by your customers as they browse on your website.',
        ],
      }
    ]}
  />
)
